import { toast } from "vue3-toastify";

import type { PlayerRewardsData } from "@/types";

const rewardDataOrder = {
	email: 1,
	phone: 2,
	profile: 3
} as const;

const showRewardsToast = ({
	type,
	title,
	coins,
	t,
	isMobile
}: {
	type: keyof PlayerRewardsData;
	title: string;
	coins: number;
	t: ReturnType<typeof useT>["t"];
	isMobile: boolean;
}) => {
	toast.success(
		`
              <div class="toast-reward-title">${title}</div>
              <div class="toast-reward-content">
                ${t("You’ve earned")}
                <b class="text-coimbatore">
                    ${numberFormat(coins ?? 0)}
                </b>
                ${t("Coins")}
              </div>`,
		{
			containerId: "toast-reward-container",
			toastId: type,
			icon: h("i", { class: `icon-reward` }),
			dangerouslyHTMLString: true,
			theme: toast.THEME.DARK,
			position: isMobile ? toast.POSITION.TOP_CENTER : toast.POSITION.BOTTOM_RIGHT,
			transition: toast.TRANSITIONS.SLIDE,
			autoClose: 5000
		}
	);
};

const useProfileRewards = () => {
	const { open, close } = useFunrizeModals();
	const { t } = useT();
	const { isMobile } = useDevice();
	const { data: rewardsData, rewardsSum } = useRewardsData();
	const { data: appInit } = useAppInitData();

	const { duration, durationLeft, handleRequestEmail } = useFunrizeEmailConfirm();

	const isActiveRankLeague = computed(() => !!appInit.value?.rankLeague);

	const calculateOrder = (name: keyof typeof rewardDataOrder, completed: boolean) => {
		const order = rewardDataOrder[name];

		if (order) {
			return completed ? order + Object.keys(rewardDataOrder).length : order;
		}

		return 0;
	};
	const handleClick = (name: string, completed: boolean) => {
		if (completed) {
			return;
		}

		if (name === "email") {
			if (appInit.value?.email) {
				handleRequestEmail();
			}
			open("LazyOModalEmailConfirm");
			return;
		}

		if (name === "phone") {
			if (appInit.value?.phone) {
				open("LazyOModalPhoneConfirmation", { goToSecondStep: true });
				return;
			}
			open("LazyOModalPhoneConfirmation");
		}

		if (name === "profile") {
			window?.$cash?.$router?.push("/cash/account/?isOutsideCash=true");
		}
	};

	const setRewardComplete = ({
		type,
		completed,
		title,
		closeRewards
	}: {
		type: keyof PlayerRewardsData;
		completed: boolean;
		title: string;
		closeRewards: boolean;
	}) => {
		rewardsData.value.data[type].completed = completed;

		if (!rewardsData.value.data[type].available) {
			return;
		}
		setTimeout(() => {
			showRewardsToast({ type, title, t, coins: rewardsData.value.data[type].coins, isMobile });
		}, 500);

		if (closeRewards) {
			close("LazyOModalProfileRewards");
		}
	};

	return {
		isActiveRankLeague,
		rewardsData,
		rewardsSum,
		duration,
		durationLeft,
		calculateOrder,
		handleClick,
		setRewardComplete
	};
};

export default useProfileRewards;
